(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

(function(W, U) {
  var $ = W.jQuery,
      _ = W._,
      Promise = W.Promise,
      toastr = W.toastr;
  
  var submitLock = false;
  var submitAjax = [];
  $(document).ajaxSend(function(e, jqXHR, options) {
    if (submitLock) submitAjax.push(jqXHR);
  });

  $.fn.submit = function(data, fn) {
    if (arguments.length < 1) {
      this.trigger('submit');
      return;
    }

    if (typeof data === 'function') {
      var t = data;
      data = fn;
      fn = t;
    }

    var selected = this;
    var handler = function() {
      selected.find('button[type=submit], input[type=submit]').attr('disabled', 'disabled');
      selected.clearErrors();
      // call the original handler
      submitLock = true;
      var result = fn.apply(this, arguments);
      if (submitAjax.length) {
        // wait for all to finish
        $.when.apply($, submitAjax)
          .then(function() {
            // success
            selected.one('submit', null, data, handler);
            selected.find('button[type=submit], input[type=submit]').removeAttr('disabled');
            submitLock = false;
          }, function() {
            // failure
            selected.one('submit', null, data, handler);
            selected.find('button[type=submit], input[type=submit]').removeAttr('disabled');
            submitLock = false;
          });
      } else {
        selected.one('submit', null, data, handler);
        selected.find('button[type=submit], input[type=submit]').removeAttr('disabled');
        submitLock = false;
      }
      return result;
    };
    this.one('submit', null, data, handler);
  };

  $(document).on('show.bs.modal', '.modal', function() {
    var zIndex = 2050 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
      $('.modal-backdrop:not(.modal-stack)')
        .css('z-index', zIndex - 1)
        .addClass('modal-stack');
    }, 0);
  });

  $.modal = function(url, options) {
    if (arguments.length === 0) {
      return $('[data-modal=remote-modal]').last();
    }

    options = _.defaults(options || {}, { id: W.util.generateUUID() });
    var $M = $('[data-modal=remote-modal]#' + options.id);
    if (!$M.length) {
      $M = $('<div id="'+options.id+'" data-modal="remote-modal"><div class="modal-dialog"><div class="modal-content"/></div></div>')
        .css('display', 'none')
        .appendTo('body')
        .modal({ show: false });
    }

    $M.find('.modal-dialog').removeClass('modal-lg modal-sm');
    if (options.size) {
      $M.find('.modal-dialog').addClass(options.size);
    }

    var method = 'GET';
    if (options.method) method = options.method;

    var sendRequest = { method: method, url: url };

    if (options.data) sendRequest.data = options.data;

    if (options.is_html) {
      return new Promise(function(resolve) {
        $M.data('result', false)
          .removeClass()
          .addClass('modal fade')
          .addClass(options.class)
          .find('.modal-content').html(url).end()
          .one('hidden.bs.modal', function() {
            var result = $M.data('result');
            setTimeout(function() { $M.remove(); }, 500);
            resolve(result);
          })
          .one('shown.bs.modal', function() {
            if (typeof options.shown === 'function')
              options.shown();
          })
          .removeClass('fade')
          .modal('show');
      });
    }

    return new Promise(function(resolve) {
      $.ajax(sendRequest).done(function(html) {
        $M.data('result', false)
          .removeClass()
          .addClass('modal fade')
          .addClass(options.class)
          .find('.modal-content').html(html).end()
          .one('hidden.bs.modal', function() {
            var result = $M.data('result');
            setTimeout(function() { $M.remove(); }, 500);
            resolve(result);
          })
          .one('shown.bs.modal', function() {
            if (typeof options.shown === 'function')
              options.shown();
          })
          .removeClass('fade')
          .modal('show');
      }).fail(function(err){
        toastr.error(err.message || 'Error Loading Modal');
        resolve(false);
      });
    });
  };

  $.confirm = function(title, msg) {
    var m = $('#confirm-modal');

    console.log(arguments);

    if (typeof msg === 'undefined' || msg === null) { msg = title; title = U; }

    if (!m.length)
    {
      m = $('<div id="confirm-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">No</button><button type="button" class="btn btn-primary" data-dismiss="modal">Yes</button></div></div></div></div>')
          .on('click', '.btn', function(e) { m.data('result', $(e.target).is('.btn-primary'));  })
          .appendTo('body').modal({ show: false });
    }

    return new Promise(function(resolve) {
      m.data('result', false)
       .toggleClass('no-title', !!!title)
       .find('.modal-header').html(title).toggle(!!title).end()
       .find('.modal-body').html(msg).end()
       .one('hidden.bs.modal', function() { resolve(m.data('result')); })
       .modal('show');
    });
    
  };

  $.msgbox = function(title, msg, cb) {
    var m = $('#msgbox-modal');

    if (typeof msg === 'function') { cb = msg; msg = title; title = U; }

    if (!m.length)
    {
      m = $('<div id="msgbox-modal" class="modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"><button type="button" class="btn btn-default" data-dismiss="modal">OK</button></div></div></div></div>')
          .appendTo('body').modal({ show: false });
    }

    m.data('result', false)
     .toggleClass('no-title', !!!title)
     .find('.modal-header').html(title).toggle(!!title).end()
     .find('.modal-body').html(msg).end()
     .one('hidden.bs.modal', function() { cb(); })
     .modal('show');
  };

  function IsJSON(data) {
    try {
      var json = JSON.parse(data);
      return true;
    } catch(e) {
      return false;
    }
  }

  $.fn.offon = function() {
    var events, selector, data, handler;
    
    events = arguments[0];
    if (typeof arguments[arguments.length - 1] === 'function')
      handler = arguments[arguments.length - 1];
    if (arguments.length > 1 && typeof arguments[1] === 'string')
      selector = arguments[1];
    if (arguments.length > 2 && typeof arguments[2] !== 'function')
      data = arguments[2];
    
    this.off(events, selector, handler)
        .on(events, selector, data, handler);
  };

  $.fn.clearErrors = function() {
    this.find('.hasError').removeClass('hasError');
  };

  $.fn.ajaxData = function() {
    var data = {};
    $.each(this.serializeArray(), function(i, field) {
      data[field.name] = field.value;
    });
    return data;
  };
  
  $.handleFormErrors = function(selector, callback) {
    return function(result, status, xhr) {
      if (result.status === 0){
        toastr.error("<strong>Connection Issue</strong>: There is an issue connecting to the server right now");
      }else{
        if(IsJSON(result.responseText) && JSON.parse(result.responseText).errors != null){
          result.response = JSON.parse(result.responseText);
          _.each(result.response.errors, function(err){
            toastr.warning(err.message);
            var obj = null;
            if (selector) {
              obj = $('[name=' + err.param + ']', $(selector)).addClass('hasError');
            } else {
              obj = $('[name=' + err.param + ']').addClass('hasError');
            }

            if (obj && obj.attr('type') === 'hidden') {
              var next = obj.next();
              if (next && (next.hasClass('phone-with-ext') || next.hasClass('intl-tel-input'))) {
                next.find('#' + err.param).addClass('hasError');
              }
            }
          });
          toastr.error(result.response.message);
        }else{
          toastr.error(result.responseText);
        }
      }
      if (typeof callback === 'function')
        callback(result, status, xhr);
    };
  };

  $('.select2-basic-clear').each(function() {
    var placeholder = $(this).attr('placeholder') || '';
    $(this).select2({
      placeholder: placeholder,
      allowClear: true
    });
  });

  $.fn.syncInput = function(sourceSelector) {
    if (this.is('input')) {
      switch(this.attr('type')) {
        case 'text':
          $(sourceSelector).off('keyup', syncInput).data('sync-target', this).on('keyup', syncInput).keyup();
          break;
      }
      this.prop('readonly', true);
    } else {
      $(sourceSelector).off('change', syncInput).data('sync-target', this).on('change', syncInput).change();

      var hidden = $('<input type="hidden" />').attr('name', this.attr('name')).val(this.val());
      this.prop('disabled', true);
      this.after(hidden);
    }
    this.data('sync-source', sourceSelector);
    return this;
  };

  $.fn.disableSyncInput = function() {
    var sourceSelector = this.data('sync-source');
    if (sourceSelector) {
      if (this.is('input')) {
        switch(this.attr('type')) {
          case 'text':
            $(sourceSelector).off('keyup', syncInput);
            break;
        }
      } else {
        $(sourceSelector).off('change', syncInput);
        
        var next = this.next();
        if (next && next.attr('type') === 'hidden' && next.attr('name') == this.attr('name')) {
          next.remove();
        }
        this.prop('disabled', false);
      }
    }
    this.prop('readonly', false);
    return this;
  };

  function syncInput() {
    var target = $(this).data('sync-target');
    if (target) {
      $(target).val($(this).val()).change();
      var next = $(target).next();
      if (next && next.attr('type') === 'hidden' && next.attr('name') === $(target).attr('name')) {
        next.val($(this).val()).change();
      }
    }
  }

  $('.view-select').click(function () {
    var clicked = $(this);
    if(!clicked.hasClass('active')) {

      var data = '';
      if (clicked.data('listview')) {
        data = true;
      }
      $.ajax({
        url: '/set-view',
        method: 'get',
        data: {listView: data},
        success: function (res) {
          console.log("Session view set!");
          $('.flex-row').toggleClass('list-view');
          $('.view-select.active').toggleClass('active');
          clicked.toggleClass('active');

        },
        error: function (res) {
          console.log("Error occurred while trying to set session view");
        }
      });
    }
  });

  $('.remove').click(function () {
    var clicked = $(this);
    var data = $(this).data('id');
    $.ajax({
      url: '/quote/remove',
      method: 'get',
      data: {machine: data},
      success: function (res) {
        clicked.parent().remove();
      },
      error: function (res) {
        console.log("Error occurred while trying to set session view");
      }
    });
  });
}(self));

},{}]},{},[1])

